import dayjs from 'dayjs'
import { useTranslation } from 'next-i18next'
import { DurationTimestampProps } from 'components/DurationTimeStamp/types'

const DurationTimeStamp = ({ startDate = dayjs(), endDate }: DurationTimestampProps): string => {
  // I18N
  const { t } = useTranslation('referral')

  // Constants
  const millisecondsPerHour = 1000 * 60 * 60
  const millisecondsPerDay = millisecondsPerHour * 24
  const remainingTime = dayjs(endDate).diff(dayjs(startDate))
  const remainingDays = Math.round(remainingTime / millisecondsPerDay)
  const remainingHours = Math.round((remainingTime % millisecondsPerDay) / millisecondsPerHour)

  return t('activeAction.actionBlock.timeLeft', { days: remainingDays, hours: remainingHours })
}

export default DurationTimeStamp
