import styles from 'features/referral/components/ReferralForm/ReferralForm.module.scss'
import { EmailShareButton, FacebookMessengerShareButton, WhatsappShareButton } from 'react-share'
import classNames from 'classnames'
import Icon from 'components/icon/Icon'
import { ShareButtonProps } from 'features/referral/components/ShareButtons/types'
import { useTranslation } from 'next-i18next'
import { FACEBOOK_APP_ID } from 'constants/envs'
import dayjs from 'dayjs'
import { COMMUNITY_SALES_DEFAULT_REDUCTION } from 'features/referral/constants'

const ShareButtons = ({ referralLink, activeAction }: ShareButtonProps) => {
  // I18N
  const { t } = useTranslation(['referral'])

  // Constants
  const reduction = activeAction?.reduction || COMMUNITY_SALES_DEFAULT_REDUCTION
  const rootKey = activeAction ? 'activeAction' : 'referral'
  const share = {
    subject: t(`${rootKey}.share.title`, {
      reduction
    }),
    body: t(`${rootKey}.share.message`, {
      endDate: activeAction ? dayjs(activeAction.end).format('D MMMM') : '',
      reduction
    })
  }

  return (
    <ul className={styles.shareButtons}>
      <li>
        <EmailShareButton url={referralLink} subject={share.subject} body={share.body}>
          <span className={classNames(styles['share-btn'])}>
            <Icon name="email" color="currentColor" size={24} />
          </span>
        </EmailShareButton>
      </li>
      <li>
        <FacebookMessengerShareButton title={share.subject} appId={FACEBOOK_APP_ID} url={referralLink}>
          <span className={classNames(styles['share-btn'])}>
            <Icon name="messenger" color="currentColor" size={24} />
          </span>
        </FacebookMessengerShareButton>
      </li>
      <li>
        <WhatsappShareButton url={referralLink} title={share.subject}>
          <span className={classNames(styles['share-btn'])}>
            <Icon name="whatsapp" color="currentColor" size={24} />
          </span>
        </WhatsappShareButton>
      </li>
    </ul>
  )
}

export default ShareButtons
