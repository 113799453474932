import styles from '../../Referral.module.scss'
import { useTranslation } from 'next-i18next'
import StickyOffering from 'components/sections/bolt-offering/sticky-offering/StickyOffering'
import Hero from 'components/sections/hero/Hero'
import FaqSection from 'components/faq/faq-section/FaqSection'
import { COMMUNITY_SALES_DEFAULT_REDUCTION } from 'features/referral/constants'
import { ReferralProps } from 'features/referral/types'

const Referee = ({ activeAction, faqQuestions, referralCode }: ReferralProps) => {
  // I18N
  const { t } = useTranslation('referral')

  // Constants
  const amount = activeAction?.reduction || COMMUNITY_SALES_DEFAULT_REDUCTION
  const firstName = referralCode.referrer.firstName

  return (
    <main>
      <Hero
        withSimulationForm
        image={{
          alt: '',
          customClass: styles.heroImage,
          publicId: 'website/producers/citibee'
        }}
      >
        <Hero.Title
          value={t('referee.hero.title', 'Dankzij {{firstName}} krijg je € {{amount}} korting', {
            firstName,
            amount
          })}
          className={styles.heroTitle}
        />
        <Hero.Paragraph
          value={t('referee.hero.paragraph', 'Word net als {{firstName}} energie-afnemer bij Bolt en krijg € {{amount}} vriendenkorting.', {
            firstName,
            amount
          })}
        />
      </Hero>

      {/* Sticky Cards */}
      <StickyOffering title={t('referee.whyBolt', 'Waarom kiezen voor Bolt?')} />

      {/*	FAQ */}
      {!!faqQuestions?.length && <FaqSection questions={faqQuestions} />}
    </main>
  )
}

export default Referee
