import { COMMUNITY_SALES_ACTIONS } from 'features/referral/constants'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { ActiveActionPeriod } from 'features/referral/types'

dayjs.extend(isBetween)

/**
 * Get the active community sales action
 * @returns {ActiveActionPeriod | undefined}
 */
export const getActiveCommunitySalesAction = (): ActiveActionPeriod | undefined =>
  COMMUNITY_SALES_ACTIONS.find((action) => dayjs().isBetween(action.start, action.end))
