import styles from '../../Referral.module.scss'
import ReferralForm from 'features/referral/components/ReferralForm/ReferralForm'
import { useTranslation } from 'next-i18next'
import parse from 'html-react-parser'
import HowItWorks from 'features/referral/components/HowItWorks/HowItWorks'
import BackToTop from 'features/referral/components/BackToTop/BackToTop'
import StickyOffering from 'components/sections/bolt-offering/sticky-offering/StickyOffering'
import Hero from 'components/sections/hero/Hero'
import FaqSection from 'components/faq/faq-section/FaqSection'
import Tag from 'components/tag/Tag'
import { PredefinedTagColors } from 'components/tag/types'
import Drawer from 'components/drawer/Drawer'
import { useState } from 'react'
import Icon from 'components/icon/Icon'
import SplitSection from 'components/split-section/SplitSection'
import { Button } from '@boltenergy-be/design-system'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'
import Img from 'components/img/Img'
import { COMMUNITY_SALES_DEFAULT_REDUCTION, DAYJS_THRESHOLDS } from 'features/referral/constants'
import DurationTimeStamp from 'components/DurationTimeStamp/DurationTimeStamp'
import { ReferralProps } from 'features/referral/types'

dayjs.extend(relativeTime, {
  thresholds: DAYJS_THRESHOLDS,
  rounding: Math.floor
})

const Referral = ({ activeAction, faqQuestions }: ReferralProps) => {
  // I18N
  const { t } = useTranslation('referral')

  // Local state
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  // Constants
  const amount = activeAction?.reduction || COMMUNITY_SALES_DEFAULT_REDUCTION
  const rootKey = activeAction ? 'activeAction' : 'referral'

  return (
    <>
      <main>
        {/* HERO Intro */}
        <Hero
          image={{
            alt: t(`${rootKey}.hero.title`, { amount }),
            customClass: styles.heroImage,
            publicId: 'website/community-sales/rw-action'
          }}
        >
          {!!activeAction && (
            <Tag color={PredefinedTagColors.YELLOW}>
              <Icon name="action" size={16} />
              {t('activeAction.badge', 'Actie')}
            </Tag>
          )}
          <Hero.Title className={styles['hero-title']} value={parse(t(`${rootKey}.hero.title`, { amount }))} />
          <Hero.Paragraph
            value={parse(
              t(`${rootKey}.hero.paragraph`, { amount, endDate: activeAction ? dayjs(activeAction?.end).format('D MMMM') : '' })
            )}
          />

          {/* REFERRAL Form*/}
          <ReferralForm {...{ activeAction }} />
        </Hero>

        {/*	How it works */}
        <HowItWorks reward={amount} activeAction={!!activeAction} />

        {/* HERO Action */}
        {!!activeAction && (
          <SplitSection className={styles['action-section']}>
            <SplitSection.Left>
              <div>
                <span className={styles.timer} suppressHydrationWarning>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.99984 0.833252C5.05229 0.833252 1.0415 4.84404 1.0415 9.79158C1.0415 14.7392 5.05229 18.7499 9.99984 18.7499C14.9474 18.7499 18.9582 14.7392 18.9582 9.79158C18.9582 4.84404 14.9474 0.833252 9.99984 0.833252ZM10.8332 5.83325C10.8332 5.37301 10.4601 4.99992 9.99984 4.99992C9.5396 4.99992 9.1665 5.37301 9.1665 5.83325V9.99992C9.1665 10.269 9.29648 10.5216 9.51547 10.678L12.4321 12.7614C12.8067 13.0289 13.3271 12.9421 13.5946 12.5676C13.8621 12.1931 13.7754 11.6726 13.4009 11.4051L10.8332 9.57107V5.83325Z"
                      fill="currentColor"
                    />
                  </svg>
                  <DurationTimeStamp endDate={activeAction.end} />
                </span>
                <h2 className={styles['action-title']}>{t('activeAction.actionBlock.title')}</h2>
              </div>

              <p className={styles.heroParagraph} suppressHydrationWarning>
                {parse(
                  t('activeAction.actionBlock.description', {
                    amount: activeAction.reduction,
                    endDate: dayjs(activeAction.end).format('D MMMM')
                  })
                )}
              </p>

              {/* More info */}
              <Button onClick={() => setDrawerOpen(true)} className={styles['more-info-btn']} variant="tertiary">
                {t('common:moreInfo')}
              </Button>
            </SplitSection.Left>
            <SplitSection.Right>
              <Img publicId="website/community-sales/rock_werchter" fill alt="" />
            </SplitSection.Right>
          </SplitSection>
        )}

        {/* Sticky Cards */}
        <StickyOffering title={t('referral.convince.title')} paragraph={t('referral.convince.description')} />

        {/* Back to Top */}
        <BackToTop />

        {/* FAQ */}
        {!!faqQuestions?.length && <FaqSection questions={faqQuestions} />}
      </main>

      {/* Drawer */}
      {activeAction && (
        <Drawer
          isOpen={drawerOpen}
          onRequestClose={() => setDrawerOpen(false)}
          title={t('activeAction.drawer.title')}
          contentClassName={styles.drawer}
        >
          {parse(
            t('activeAction.drawer.text', {
              endDate: dayjs(activeAction.end).format('D MMMM'),
              fullEndDate: dayjs(activeAction.end).format('D MMMM YYYY'),
              fullStartDate: dayjs(activeAction.start).format('D MMMM'),
              reduction: activeAction.reduction
            })
          )}
        </Drawer>
      )}
    </>
  )
}

export default Referral
