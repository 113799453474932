import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { DEFAULT_NAMESPACES } from 'constants/i18n'
import { stringify } from 'qs'
import { getFaqQuestions } from 'api/cms/faq'
import { ReferralProps } from 'features/referral/types'
import { faqCategoriesSlugs } from 'constants/faq-categories'
import { FAQCategories } from 'features/faq-page/types'
import { checkPromoReferralCode } from 'api/customerFlow'
import { routes } from 'utils/routes'
import dayjs from 'dayjs'
import { COMMUNITY_SALES_DEFAULT_REDUCTION } from 'features/referral/constants'
import { getActiveCommunitySalesAction } from 'features/referral/utils'
import React from 'react'
import Layout from 'components/layout/Layout'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import Referee from 'features/referral/views/Referee/Referee'
import Referral from 'features/referral/views/Referral/Referral'

const ReferralPage = ({ faqQuestions, referralCode }: Omit<ReferralProps, 'activeAction'>) => {
  // React router
  const { locale } = useRouter()

  // i18n
  const { t } = useTranslation('referral')

  // Constants
  const activeAction = getActiveCommunitySalesAction()
  const amount = activeAction?.reduction || COMMUNITY_SALES_DEFAULT_REDUCTION
  const firstName = referralCode?.referrer.firstName ?? ''
  const rootKey = referralCode ? 'referee' : activeAction ? 'activeAction' : 'referral'

  return (
    <Layout
      seo={{
        canonical: `https://boltenergie.be/${routes(locale).referral}`,
        description: t(`${rootKey}.seo.paragraph`, {
          firstName,
          amount,
          endDate: activeAction ? dayjs(activeAction.end).format('D MMMM') : ''
        }),
        image: `/images/seo/referral/${activeAction?.image ? activeAction.image : COMMUNITY_SALES_DEFAULT_REDUCTION.toString() + '-referral'}-${locale}.jpg`,
        title: t(`${rootKey}.seo.title`, {
          firstName,
          amount
        })
      }}
    >
      {referralCode ? <Referee {...{ activeAction, faqQuestions, referralCode }} /> : <Referral {...{ activeAction, faqQuestions }} />}
    </Layout>
  )
}

export const getServerSideProps = async ({ locale, query }) => {
  // Get the FAQ questions & referral code
  const [faqQuestions, referralCodeResponse] = await Promise.all([
    getFaqQuestions(
      stringify({
        filters: {
          categories: {
            slug: {
              $in: faqCategoriesSlugs[FAQCategories.REFERRAL][locale]
            }
          }
        },
        locale
      })
    ),
    query.referralcode ? checkPromoReferralCode(query.referralcode as string) : undefined
  ])

  return {
    props: {
      faqQuestions,
      referralCode: referralCodeResponse?.valid && referralCodeResponse?.referralCode ? referralCodeResponse.referralCode : null,
      ...(await serverSideTranslations(locale, [...DEFAULT_NAMESPACES, 'referral']))
    }
  }
}

export default ReferralPage
