import { ActiveActionPeriod } from 'features/referral/types'

export const COMMUNITY_SALES_DEFAULT_REDUCTION = 50

export const COMMUNITY_SALES_ACTIONS: ActiveActionPeriod[] = [
  {
    id: 1,
    start: '2024-04-10',
    end: '2024-05-9 23:59',
    reduction: 50
  },
  {
    id: 2,
    start: '2024-05-10',
    end: '2024-06-23 23:59',
    reduction: 50,
    image: 'rw-50'
  }
]

// These thresholds are used to overwrite the default thresholds of dayjs. They are used to determine the rounding of the relative time.
// More information can be found at: https://day.js.org/docs/en/customization/relative-time
export const DAYJS_THRESHOLDS = [
  { l: 's', r: 1 },
  { l: 'm', r: 1 },
  { l: 'mm', r: 59, d: 'minute' },
  { l: 'h', r: 1 },
  { l: 'hh', r: 23, d: 'hour' },
  { l: 'd', r: 1 },
  { l: 'dd', r: 32, d: 'day' },
  { l: 'M', r: 1 },
  { l: 'MM', r: 11, d: 'month' },
  { l: 'y', r: 1 },
  { l: 'yy', d: 'year' }
]
