import styles from './HowItWorks.module.scss'
import { useTranslation } from 'next-i18next'
import parse from 'html-react-parser'
import classNames from 'classnames'
import { Heading } from '@boltenergy-be/design-system'
import { HowItWorksProps } from 'features/referral/components/HowItWorks/types'
import { COMMUNITY_SALES_DEFAULT_REDUCTION } from 'features/referral/constants'
import Icon from 'components/icon/Icon'

const HowItWorks = ({ reward = COMMUNITY_SALES_DEFAULT_REDUCTION, activeAction = false }: HowItWorksProps) => {
  // I18N
  const { t } = useTranslation(['referral'])

  return (
    <section className="container section">
      <Heading as="h2" variant="h3">
        {t('referral.howItWorks.title')}
      </Heading>
      <ol className={styles.steps}>
        <li className={styles.step}>
          <span className={styles.stepNumber}>1</span>
          <div className={styles.stepBody}>
            <Heading as="h3" variant="h4">
              {t('referral.howItWorks.one.title')}
            </Heading>
            <p>{parse(t('referral.howItWorks.one.description'))}</p>
          </div>
        </li>

        <li className={classNames(styles.step, styles.centered)}>
          <span className={styles.stepNumber}>2</span>
          <div className={styles.stepBody}>
            <Heading as="h3" variant="h4">
              {t('referral.howItWorks.two.title')}
            </Heading>
            <p>{parse(t('referral.howItWorks.two.description'))}</p>
          </div>
        </li>

        <li className={styles.step}>
          <span className={styles.stepNumber}>3</span>
          <div className={styles.stepBody}>
            <Heading as="h3" variant="h4">
              {t('referral.howItWorks.three.title', { reward })}
            </Heading>
            <p>{parse(t('referral.howItWorks.three.description', { reward }))}</p>
          </div>
        </li>

        {/* ROCK WERCHTER */}
        {!!activeAction && (
          <li className={classNames(styles.step, styles['step-rw'])}>
            <span className={styles.stepNumber}>
              <Icon name="gift" />
            </span>
            <div className={styles.stepBody}>
              <Heading as="h3" variant="h4">
                {t('activeAction.howItWorks.title')}
              </Heading>
              <p>{parse(t('activeAction.howItWorks.description'))}</p>
            </div>
          </li>
        )}
      </ol>
    </section>
  )
}

export default HowItWorks
